import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { SignupService } from "./signup.service";

@Injectable({
    providedIn: 'root'
})

export class SEOService {

    hostCountriesCount = 0;

    constructor(
        private title: Title,
        private meta: Meta,
        @Inject(DOCUMENT) private document: any,
        private router: Router,
        private _route: ActivatedRoute, 
        private signupService: SignupService
    ) {

        this.hostCountriesCount = Number(localStorage.getItem('hostCountriesCount')); 

        if(!this.hostCountriesCount){
            this.signupService.getHostCountries().subscribe(list => {
                this.hostCountriesCount = list['data'].length;
            })
        }
    }

    langs: string[] = [
        'en',
        'fr',
        'de',
        'it',
        'he',
        'ar',
        'zh-CN',
        'es',
        'ru',
        'pt',
        'id',
        'ja',
        'ms',
        'sv',
        'no',
        'nl'
    ]

    createCanonicalUrl() {
        this.destroyLinkForCanonicalURL()
        let link: HTMLLinkElement = this.document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', this.document.URL)
        this.document.head.appendChild(link);

    }
    destroyLinkForCanonicalURL() {
        const els = this.document.querySelectorAll('link[rel=\'canonical\']');
        if (els) {
            for (let i = 0, l = els.length; i < l; i++) {
                const el = els[i];
                el.remove();
            }
        } else {
            return;
        }
    }

    createMapsScript() {
        if (window.document.getElementById('mapsScript')) {
            return;
        } else {
            const mapsScript = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBgm0AbeCNUr-VgD93TcZTVZ_3Z-VZB5nI&libraries=places&language=en&callback=Function.prototype";
            const scriptEl = window.document.createElement('script');
            scriptEl.src = mapsScript;
            scriptEl.id = 'mapsScript';
            scriptEl.defer = true;
            window.document.body.appendChild(scriptEl);
        }
    }


    createHrefLangs() {
        this.destroyHrefLangs()
        const url = this.router.url;
        let urlParts = url.split('/');
        let routelang = urlParts[1];

        for (let index = 0; index < this.langs.length; index++) {
            let lang = this.langs[index];
            if (lang == 'iw') {
                lang = 'he'
            }
            urlParts[1] = lang;
            let alternateRouteUrl = urlParts.join('/');
            let alternateURL = 'https://' + this.document.location.hostname + alternateRouteUrl;
            let linkHrefLang: HTMLLinkElement = this.document.createElement('link');
            linkHrefLang.setAttribute('rel', 'alternate');
            linkHrefLang.setAttribute('hreflang', lang)
            linkHrefLang.setAttribute('href', alternateURL)
            this.document.head.appendChild(linkHrefLang)
        }
        let xdefault: HTMLLinkElement = this.document.createElement('link');
        xdefault.setAttribute('rel', 'alternate');
        xdefault.setAttribute('hreflang', 'x-default')
        xdefault.setAttribute('href', 'https://' + this.document.location.hostname + url);
        this.document.head.appendChild(xdefault)



    }

    destroyHrefLangs() {
        const els = this.document.querySelectorAll('link[rel=\'alternate\']');
        for (let i = 0, l = els.length; i < l; i++) {
            const el = els[i];
            el.remove();
        }
    }

    createOrganizationSchema() {
        if (window.document.getElementById('organizationSchema')) {
            return;
        } else {
            const organizationSchema = {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://www.shippn.com/en/home",
                "logo": "https://d1we2hgzmci25y.cloudfront.net/assets/img/shippn-logo-black.webp",
                "image": "https://d1we2hgzmci25y.cloudfront.net/assets/img/shippn-logo-black.webp",
                "name": "Shippn",
                "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`,
                "telephone": "+1-740-848-5020",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "8 The Green Ste 4000",
                    "addressLocality": "Dover",
                    "addressRegion": "DE",
                    "postalCode": "19901",
                    "addressCountry": "US"
                },
                "vatID": "92-0528434"
            }
            const scriptEl = window.document.createElement('script');
            scriptEl.type = 'application/ld+json';
            scriptEl.innerHTML = JSON.stringify(organizationSchema);
            scriptEl.id = 'organizationSchema';
            window.document.head.appendChild(scriptEl);
        }
    }

    removeOrganizationSchema(){
        const organizationSchemaScript = window.document.getElementById('organizationSchema'); 

        if(organizationSchemaScript){
            window.document.head.removeChild(organizationSchemaScript);
        }
    }


    createReviewsSchema() {
        if (window.document.getElementById('reviewsScript')) {
            return;
        } else {
            const reviewsData = [
                {
                    "@context": "https://schema.org/",
                    "@type": "AggregateRating",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/united-states-of-america.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "ratingValue": "5",
                    "reviewCount": "7"
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/united-states-of-america.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Nicholas"
                    },
                    "reviewBody": "I didn't know such a service existed and when I found it, I was skeptical at first. But the whole process was seamless, the website was clear and informative and Stanislav (who collected and shipped the items) kept me up to date on every step. Got here quickly too. Very satisfied!"
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/united-states-of-america.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Anita"
                    },
                    "reviewBody": "I'm a collector of a particular brand of product from around the world. Since discovering shippn two months ago, I've been able to shop for my favorite things in new markets such as Australia and South Africa. I've placed three orders with Shippn so far, and the fragile and hard to find items have all reached me quickly and in perfect condition. I've worked with two separate hosts on the three orders and both were a pleasure to work with and very responsive."
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/belgium.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "A. Brahm"
                    },
                    "reviewBody": "This is my 3rd experience with Shipping and it went marvelously as it was the case for my two former experiences with them. I trusted in the same host -- Mr. Kelby Cody -- and I'm so happy to have met him as a host. Kelby is such a great guy you can trust in. He always does the right thing, the fast and easy way. Kelby never forgets to get you updated about what's going on with your purchase. I really recommend Kelby and of course Shippn."
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/united-kingdom.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Elaine"
                    },
                    "reviewBody": "I was a little dubious to start with. But I could not find the item I wanted anywhere but Australia and they would not deliver to the UK. So I tried this service, fantastic. They kept me updated all the way through and I received my item in less than 2 weeks. I would definitely use them again."
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/germany.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Daniil"
                    },
                    "reviewBody": "Absolutely great experience, top service - the best customer experience i have ever had. I came to Shippn with a very difficult task, to secure an expensive (4000 USD) high demand product on launch day in the US and to ship it to Germany as fast as possible. Despite all difficulties, they secured me the product one minute after the presale opened (it was 5AM in the US at that moment), carried me through all further steps to prepare and get the product delivered to Germany."
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/netherlands.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "TjadaL"
                    },
                    "reviewBody": "Great! Recieved my fabrics etc in perfect state from Indonesia to the Netherlands. The Host (Salsabila) was very kind and updated me by every step. I absolutely will make use of this service again when needed :)"
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/japan.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Jee"
                    },
                    "reviewBody": "I’m in Japan and the size for the boots I needed to snowboard was sold out completely even in the states so I am very happy with my Shippn host who helped me get what I needed so I can go snowboarding! Fast, quick and easy process!"
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/canada.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "James"
                    },
                    "reviewBody": "I live in Canada and wanted an item on the Polish site, Allegro Lokalnie. Previously I had used another service which required delivery of the purchased item to their warehouse. But this new seller would only deliver to a locker box. So I found Shippn with a Google search and it worked out great! The Host picked up the item at a locker box and shipped it to me. Shippn's communications were superb. The estimated shipping costs were communicated in advance so I knew what I was getting into."
                }
            ];
            const scriptElement = this.document.createElement('script');
            scriptElement.type = 'application/ld+json';
            scriptElement.textContent = JSON.stringify(reviewsData);
            scriptElement.id = 'reviewsScript'
            this.document.head.appendChild(scriptElement);
        }
    }

    removeReviewsSchema() {
        const scriptTags = document.querySelectorAll('script');
        scriptTags.forEach(script => {
            if (script.id.includes('reviewHost') || script.id.includes('reviewsScript')) {
                this.document.head.removeChild(script);
            }
        });
    }

    createDynamicReviewsForHost(hostName, hostReviewData) {


        const hostReviewScriptID = `reviewHost-${hostName}`;

        if (window.document.getElementById(hostReviewScriptID)) {
            return;
        } else {
            const reviewsData = [
                {
                    "@context": "https://schema.org/",
                    "@type": "AggregateRating",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/united-states-of-america.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "ratingValue": "5",
                    "reviewCount": "7"
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/united-states-of-america.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Nicholas"
                    },
                    "reviewBody": "I didn't know such a service existed and when I found it, I was skeptical at first. But the whole process was seamless, the website was clear and informative and Stanislav (who collected and shipped the items) kept me up to date on every step. Got here quickly too. Very satisfied!"
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/united-states-of-america.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Anita"
                    },
                    "reviewBody": "I'm a collector of a particular brand of product from around the world. Since discovering shippn two months ago, I've been able to shop for my favorite things in new markets such as Australia and South Africa. I've placed three orders with Shippn so far, and the fragile and hard to find items have all reached me quickly and in perfect condition. I've worked with two separate hosts on the three orders and both were a pleasure to work with and very responsive."
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/belgium.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "A. Brahm"
                    },
                    "reviewBody": "This is my 3rd experience with Shipping and it went marvelously as it was the case for my two former experiences with them. I trusted in the same host -- Mr. Kelby Cody -- and I'm so happy to have met him as a host. Kelby is such a great guy you can trust in. He always does the right thing, the fast and easy way. Kelby never forgets to get you updated about what's going on with your purchase. I really recommend Kelby and of course Shippn."
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/united-kingdom.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Elaine"
                    },
                    "reviewBody": "I was a little dubious to start with. But I could not find the item I wanted anywhere but Australia and they would not deliver to the UK. So I tried this service, fantastic. They kept me updated all the way through and I received my item in less than 2 weeks. I would definitely use them again."
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/germany.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Daniil"
                    },
                    "reviewBody": "Absolutely great experience, top service - the best customer experience i have ever had. I came to Shippn with a very difficult task, to secure an expensive (4000 USD) high demand product on launch day in the US and to ship it to Germany as fast as possible. Despite all difficulties, they secured me the product one minute after the presale opened (it was 5AM in the US at that moment), carried me through all further steps to prepare and get the product delivered to Germany."
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/netherlands.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "TjadaL"
                    },
                    "reviewBody": "Great! Recieved my fabrics etc in perfect state from Indonesia to the Netherlands. The Host (Salsabila) was very kind and updated me by every step. I absolutely will make use of this service again when needed :)"
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/japan.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Jee"
                    },
                    "reviewBody": "I’m in Japan and the size for the boots I needed to snowboard was sold out completely even in the states so I am very happy with my Shippn host who helped me get what I needed so I can go snowboarding! Fast, quick and easy process!"
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "Review",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name": "Shippn",
                        "image": "https://www.shippn.com/assets/img/flags/canada.png",
                        "description": `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn.`
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "James"
                    },
                    "reviewBody": "I live in Canada and wanted an item on the Polish site, Allegro Lokalnie. Previously I had used another service which required delivery of the purchased item to their warehouse. But this new seller would only deliver to a locker box. So I found Shippn with a Google search and it worked out great! The Host picked up the item at a locker box and shipped it to me. Shippn's communications were superb. The estimated shipping costs were communicated in advance so I knew what I was getting into."
                }
            ];
            const scriptElement = this.document.createElement('script');
            scriptElement.type = 'application/ld+json';
            scriptElement.textContent = JSON.stringify(hostReviewData);
            scriptElement.id = hostReviewScriptID;
            this.document.head.appendChild(scriptElement);
        }
    }
}